import AVAILABLE_ENVIRONMENTS from '../config';

export { AVAILABLE_ENVIRONMENTS as environments };

export default {
  ...process.env,
  // eslint-disable-next-line dot-notation
  ...(AVAILABLE_ENVIRONMENTS['__RUNTIME_ENV__'] ||
    AVAILABLE_ENVIRONMENTS.development),
};
